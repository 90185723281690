import { forwardRef, useRef, useState, useEffect } from 'react';
import TawkTo from 'tawkto-react';
import PropTypes from 'prop-types';
import Gleap from 'gleap';
import { Icon } from '@iconify/react';
import { Link } from '@mui/material';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// material
import { alpha, useTheme, styled } from '@material-ui/core/styles';
import ListItemText from '@mui/material/ListItemText';
import {
  Box,
  List,
  Collapse,
  ListItemIcon,
  ListSubheader,
  ListItemButton,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Popover
} from '@material-ui/core';
import useAuth from '../hooks/useAuth';
import ManualDialog from './_dashboard/manual/ManualDialog';

// ----------------------------------------------------------------------

const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(5),
    color: theme.palette.text.primary
  })
);

export const StyledIcon = styled(ListItemIcon)(({ size }) => ({
  width: size,
  height: size,
  flexShrink: 0,
  marginRight: 0
}));

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(3),
  color: theme.palette.text.secondary,
  '&:before': {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: 'none',
    position: 'absolute',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main
  }
}));

const ListItemStyleCollapse = styled(forwardRef((props) => <ListItemButton disableGutters {...props} />))(
  ({ theme }) => ({
    ...theme.typography.body2,
    height: 48,
    position: 'relative',
    textTransform: 'capitalize',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    '&:before': {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      display: 'none',
      position: 'absolute',
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      backgroundColor: theme.palette.primary.main
    }
  })
);

const ListItemStyleCollapseRef = styled(
  forwardRef((props, ref) => <ListItemButton ref={ref} disableGutters {...props} />)
)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  color: theme.palette.text.secondary,
  '&:before': {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: 'none',
    position: 'absolute',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main
  }
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  active: PropTypes.func,
  isShow: PropTypes.bool,
  item: PropTypes.object
};

function NavItem({ item, active, isShow }) {
  const theme = useTheme();
  const navRef = useRef(null);
  const isActiveRoot = active(item.path);
  const { title, path, icon, info, children, modal, action } = item;
  const [open, setOpen] = useState(isActiveRoot);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isIframeLoading, setIsIframeLoading] = useState(true);

  const [openDialog, setOpenDialog] = useState(false);

  const handleIframeLoad = () => {
    setIsIframeLoading(false);
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const isExternalLink = (path) => path.includes('http');

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    '&:before': { display: 'block' }
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium'
  };

  if (modal) {
    return (
      <>
        <ListItemStyle
          sx={{
            ...(isActiveRoot && activeRootStyle)
          }}
          onClick={handleOpenModal}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          {isShow && (
            <>
              <ListItemText disableTypography primary={title} />
              {info && info}
            </>
          )}
        </ListItemStyle>

        <Dialog maxWidth="md" open={isModalOpen} onClose={handleCloseModal}>
          <DialogContent style={{ width: '900px', height: '800px' }}>
            {isIframeLoading ? (
              <div
                style={{
                  width: '850px',
                  height: '500px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              ''
            )}
            <div>
              <iframe
                src="https://jaodelivery.tawk.help/"
                title="Tawk Help"
                width="100%"
                height="100%"
                frameBorder="0"
                style={{ pointerEvents: 'auto', overflow: 'scroll', width: '850px', height: '800px' }}
                onLoad={handleIframeLoad}
              />
            </div>
          </DialogContent>
          <DialogActions style={{ position: 'absolute', top: '0px', left: '0px' }}>
            <Button onClick={handleCloseModal}>Fechar</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  if (children) {
    return (
      <>
        {isShow ? (
          <ListItemStyle
            onClick={handleOpen}
            sx={{
              ...(isActiveRoot && activeRootStyle)
            }}
          >
            {isShow && (
              <>
                <ListItemIconStyle>{icon}</ListItemIconStyle>
                <ListItemText disableTypography primary={title} />
                {info && info}
                <Box
                  component={Icon}
                  icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
                  sx={{ width: 16, height: 16, ml: 1 }}
                />
              </>
            )}
          </ListItemStyle>
        ) : (
          <ListItemStyleCollapseRef ref={navRef} onMouseEnter={handleOpenDialog} onMouseLeave={handleCloseDialog}>
            <div style={{ marginBottom: '10px' }}>
              <StyledIcon size={20}>{icon}</StyledIcon>
              <p style={{ fontSize: 10 }}>{title?.split(' ')[0]}</p>
            </div>
          </ListItemStyleCollapseRef>
        )}

        {openDialog && (
          <Popover
            open={openDialog}
            anchorEl={navRef.current}
            anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
            transformOrigin={{ vertical: 'center', horizontal: 'left' }}
            PaperProps={{
              onMouseEnter: handleOpenDialog,
              onMouseLeave: handleCloseDialog,
              sx: {
                width: 250,
                ...(openDialog && {
                  pointerEvents: 'auto'
                })
              }
            }}
            sx={{
              pointerEvents: 'none'
            }}
          >
            <List component="div" disablePadding>
              {children.map((item) => {
                const { title, path, action } = item;
                const isActiveSub = active(path);
                const content = () => (
                  <ListItemIconStyle>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: 'flex',
                        borderRadius: '50%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'text.disabled',
                        transition: (theme) => theme.transitions.create('transform'),
                        ...(isActiveSub && {
                          transform: 'scale(2)',
                          bgcolor: 'primary.main'
                        })
                      }}
                    />
                  </ListItemIconStyle>
                );

                if (path.includes('http')) {
                  return (
                    <ListItemStyle
                      key={title}
                      component={Link}
                      href={path}
                      target="_blank"
                      rel="noopener"
                      sx={{
                        ...(isActiveSub && activeSubStyle)
                      }}
                    >
                      {content()}
                      <ListItemText disableTypography primary={title} />
                    </ListItemStyle>
                  );
                }
                if (action) {
                  return (
                    <ListItemStyle
                      key={title}
                      onClick={() => action()}
                      sx={{
                        ...(isActiveSub && activeSubStyle)
                      }}
                    >
                      {content()}
                      <ListItemText disableTypography primary={title} />
                    </ListItemStyle>
                  );
                }

                return (
                  <ListItemStyle
                    key={title}
                    component={RouterLink}
                    to={path}
                    sx={{
                      ...(isActiveSub && activeSubStyle)
                    }}
                  >
                    {content()}
                    <ListItemText disableTypography primary={title} />
                  </ListItemStyle>
                );
              })}
            </List>
          </Popover>
        )}

        {isShow && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {children.map((item) => {
                const { title, path, action } = item;
                const isActiveSub = active(path);
                const content = () => (
                  <ListItemIconStyle>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: 'flex',
                        borderRadius: '50%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'text.disabled',
                        transition: (theme) => theme.transitions.create('transform'),
                        ...(isActiveSub && {
                          transform: 'scale(2)',
                          bgcolor: 'primary.main'
                        })
                      }}
                    />
                  </ListItemIconStyle>
                );

                if (path?.includes('http')) {
                  return (
                    <ListItemStyle
                      key={title}
                      component={Link}
                      href={path}
                      target="_blank"
                      rel="noopener"
                      sx={{
                        ...(isActiveSub && activeSubStyle)
                      }}
                    >
                      {content()}
                      <ListItemText disableTypography primary={title} />
                    </ListItemStyle>
                  );
                }
                if (action) {
                  return (
                    <ListItemStyle
                      key={title}
                      onClick={() => action()}
                      sx={{
                        ...(isActiveSub && activeSubStyle)
                      }}
                    >
                      {content()}
                      <ListItemText disableTypography primary={title} />
                    </ListItemStyle>
                  );
                }
                return (
                  <ListItemStyle
                    key={title}
                    component={RouterLink}
                    to={path}
                    sx={{
                      ...(isActiveSub && activeSubStyle)
                    }}
                  >
                    {content()}
                    <ListItemText disableTypography primary={title} />
                  </ListItemStyle>
                );
              })}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return (
    <>
      {isShow ? (
        <ListItemStyle
          component={RouterLink}
          to={path}
          sx={{
            ...(isActiveRoot && activeRootStyle)
          }}
        >
          {isShow ? (
            <>
              <StyledIcon size={20} sx={{ mr: 1.5 }}>
                {icon}
              </StyledIcon>
              <ListItemText disableTypography primary={title} />
              {info && info}
            </>
          ) : (
            // Quando isShow é falso, exibe o título abaixo do ícone
            <div style={{ marginBottom: '10px' }}>
              <StyledIcon size={20} sx={{ mr: 2 }}>
                {icon}
              </StyledIcon>
              <p>{title?.split(' ')[0]}</p>
            </div>
          )}
        </ListItemStyle>
      ) : (
        <ListItemStyleCollapse
          component={RouterLink}
          to={path}
          sx={{
            ...(isActiveRoot && activeRootStyle)
          }}
        >
          {isShow ? (
            <>
              <StyledIcon size={20} sx={{ mr: 1.5 }}>
                {icon}
              </StyledIcon>
              <ListItemText disableTypography primary={title} />
              {info && info}
            </>
          ) : (
            // Quando isShow é falso, exibe o título abaixo do ícone
            <div style={{ marginBottom: '10px' }}>
              <StyledIcon size={20}>{icon}</StyledIcon>
              <p style={{ fontSize: 10 }}>{title?.split(' ')[0]}</p>
            </div>
          )}
        </ListItemStyleCollapse>
      )}
    </>
  );
}

NavSection.propTypes = {
  isShow: PropTypes.bool,
  navConfig: PropTypes.array
};

export default function NavSection({ navConfig, isShow = true, ...other }) {
  const { pathname } = useLocation();
  const { user } = useAuth();
  const match = (path) => (path ? !!matchPath({ path }, pathname) : false);

  const userType = localStorage.getItem('typeUser');
  // eslint-disable-next-line
  const gleapKey = userType == 3 || userType == 4 ? "jWmgG5ff4dljnCUYguogYNuUvIlJBqq1" : "z3wnETZ8q45f8u034ZKWVmGgagi5kg07"

  const itemsToShow = [];

  if (userType > 2) {
    // eslint-disable-next-line
    for (let i = 0; i < navConfig.length; i++) {
      const items = navConfig[i]?.items?.filter(
        (i) =>
          !user.fields ||
          (user.fields?.includes(i.title?.toLowerCase()) && (i.type === undefined || i.type?.includes(user.type)))
      );
      if (items.length > 0) itemsToShow.push({ ...navConfig[i], items });
    }

    // eslint-disable-next-line
    if (user.fields?.includes('chat gleap') || (userType == 5 || userType == 6)) {
      Gleap.initialize(gleapKey)
      Gleap.identify(user._id, {
        name: user.name,
        email: user.email,
        tel: user.tel,
        createdAt: new Date(),
        customData: {
          city: "",
          type: userType === 3 || userType === 4 ? 'Lojista' : 'Licenciado',
        },
      });
      Gleap.setReplayOptions({
        sampling: {
          mouseInteraction: {
            MouseUp: false,
            MouseDown: false,
            Click: false,
            ContextMenu: false,
            DblClick: false,
            Focus: false,
            Blur: false,
            TouchStart: false,
            TouchEnd: false,
          }
        }
      })
    }

    if (user.fields?.includes('links úteis') && !user.fields?.includes('suporte via chat')) {
      itemsToShow[0].items = itemsToShow[0].items?.map((i) => {
        if (i.title.toLowerCase() === 'links úteis') {
          i.children = [i.children[0], i.children[1]];
        }
        return i;
      });
    }
  } else {
    itemsToShow.push(...navConfig)
  }

  return (
    <Box {...other}>
      {itemsToShow.map((list) => {
        const { subheader, items, type = [] } = list;
        if (type.length === 0 || type.includes(user.type)) {
          return (
            <List key={subheader} disablePadding>
              {isShow && <ListSubheaderStyle>{subheader}</ListSubheaderStyle>}
              {items.map((item) =>
                item.type.includes(user.type) ? (
                  <>
                    {user?.typeShop !== 0 ? (
                      <NavItem key={item.title} item={item} active={match} isShow={isShow} />
                    ) : (
                      ''
                    )}
                  </>
                ) : null
              )}
            </List>
          );
        }
        return '';
      })}
    </Box>
  );
}
