/* eslint-disable eqeqeq */
import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, styled } from '@material-ui/core/styles';
import {
  Box,
  Stack,
  AppBar,
  Menu,
  Toolbar,
  IconButton,
  FormControlLabel,
  Switch,
  Button,
  Typography,
  MenuItem
} from '@material-ui/core';
import { Alert, AlertTitle, Autocomplete, TextField } from '@mui/material';
// hooks
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Logo from '../../components/Logo';
import { useDispatch, useSelector } from '../../redux/store';
import { getRestaurant, getRestaurantList, updateRestaurantShopkeeper } from '../../redux/slices/restaurant';
import { getProductList } from '../../redux/slices/product';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import useAuth from '../../hooks/useAuth';

// components
import { MHidden, MIconButton } from '../../components/@material-extend';
import AccountPopover from './AccountPopover';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
import YayButton from '../../components/YayButton';
import useResponsive from '../../hooks/useResponsive';

// ----------------------------------------------------------------------
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 60;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: '#262626',
  [theme.breakpoints.up('lg')]: {
    width: '100%'
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

function verifyIfRestaurantCanBeOpen(restaurant, productsTotal) {
  const result = []
  if (restaurant.restaurantFee?.length <= 0 && restaurant.restaurantFeeKm?.length <= 0) result.push("O restaurante precisa ter pelo menos um bairro ou faixa de atendimento ativo para ser aberto")
  if (restaurant.opening?.length <= 0) result.push("O restaurante precisar ter pelo menos um horário de funcionamento cadastrado para ser aberto")
  if (productsTotal <= 0) result.push("O restaurante precisar ter pelo menos um produto cadastrado para ser aberto")
  if (restaurant.presencialPay <= 0 && !restaurant.onlinePay?.cc && !restaurant.onlinePay?.pix?.status && !restaurant.onlinePay?.picpay?.status) result.push("O restaurante precisa ter pelo menos uma forma de pagamento cadastrada para ser aberto")
  return result
}

export default function DashboardNavbar({ onOpenSidebar }) {
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [pendencies, setPendencies] = useState([])
  const { isCollapse } = useCollapseDrawer();
  const isDesktop = useResponsive('up', 'md');
  const { loginLicUser, user } = useAuth();
  const dispatch = useDispatch();
  const { restaurant, brothersList, restaurantList } = useSelector((state) => state.restaurant);
  const { productsTotal } = useSelector((state) => state.product);
  let aux = false;
  if (restaurant && restaurant.statusOpen === true) {
    aux = true;
  } else if (restaurant && restaurant.statusOpen === false) {
    aux = false;
  }
  const [checked, setChecked] = useState(aux);

  const handleOpen = () => {
    setOpen(true);
  };

  const changeEst = async (idRest) => {
    try {
      await loginLicUser(idRest);
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getRestaurantList(1, 999));
    dispatch(getRestaurant());
    dispatch(getProductList());
    setChecked(aux);
  }, [dispatch, aux]);

  useEffect(() => {
    if (restaurant) {
      const pendenciesList = verifyIfRestaurantCanBeOpen(restaurant, productsTotal)
      if (pendenciesList.length > 0) setPendencies(pendenciesList)
    }
  }, [restaurant])

  const changeStatus = (event) => {
    const pendenciesList = verifyIfRestaurantCanBeOpen(restaurant, productsTotal)
    if (pendenciesList.length > 0) return setPendencies(pendenciesList)
    setChecked(event.target.checked);
    dispatch(updateRestaurantShopkeeper({ statusOpen: !!event.target.checked }));
  };

  const goToManage = () => {
    localStorage.setItem('accessToken', localStorage.getItem('accessTokenLic'));
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('accessTokenLic')}`;
    localStorage.removeItem('accessTokenLic');
    window.location.href = PATH_DASHBOARD.root;
  };

  const goToAdm = () => {
    localStorage.setItem('accessToken', localStorage.getItem('accessTokenAdm'));
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('accessTokenAdm')}`;
    localStorage.removeItem('accessTokenAdm');
    window.location.href = PATH_DASHBOARD.licensed.list;
  };


  const checkStringUser = (user) => {
    let s = ""
    if (user.type === 1 || user.type === 2) {
      s = 'Administrador'
    }
    if (user.type === 2 || user.type === 3) {
      s = 'Licenciado'
    }
    if (user.type >= 4) {
      s = 'Lojista'
    }

    return s
  }

  const checkLojaName = (user) => {
    let s = ''
    if (user.type >= 4) {
      s = user.restaurant?.name || user.email
    } else {
      s = user.email
    }

    return s
  }

  return (
    <RootStyle
      sx={{
        ...(isCollapse && {
          width: '100%'
        })
      }}
    >
      <ToolbarStyle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.main' }}>
          <Icon icon={menu2Fill} />
        </IconButton>

        <p><b>{checkStringUser(user)}</b> <Typography sx={{ fontSize: 10 }}>({checkLojaName(user)})</Typography></p>

        <Box sx={{ flexGrow: 1 }} />


        <img
          alt="Logo Jão Delivery"
          src="/static/logo/logo.png"
          width="50"
          style={{
            position: !isDesktop ? 'relative' : 'absolute',
            left: !isDesktop ? '0' : '50%',
            transform: !isDesktop ? 'none' : 'translateX(-50%)'
          }}
        />

        <Box sx={{ flexGrow: 1 }} />


        {
          user?.type === 3 || user?.type === 4 ? (
            <>
              <MIconButton sx={{ mr: 1, color: 'text.main' }} ref={anchorRef} size="large" onClick={handleOpen}>
                <Icon icon="uil:exchange-alt" width={25} height={25} />
              </MIconButton>
              <Menu
                anchorEl={anchorRef.current}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    width: '200px',
                    padding: '10px'
                  }
                }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                <Autocomplete
                  onChange={(e, value) => value && changeEst(value.id)}
                  options={restaurantList?.docs?.map((rest) => ({
                    label: rest.name,
                    id: rest._id
                  }))}
                  renderInput={(params) => (
                    <TextField
                      label="Estabelecimentos"
                      {...params}
                    />
                  )}
                />
              </Menu>
            </>
          ) : (<></>)
        }

        {user.restaurant && !restaurant?.autoOpen ? (
          <FormControlLabel
            control={<Switch checked={checked} onChange={(e) => changeStatus(e)} />}
            label={checked ? 'Aberto' : 'Fechado'}
            sx={{ color: 'white' }}
          />
        ) : (
          ''
        )}

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {localStorage.getItem('accessTokenAdm') ?

            <MIconButton sx={{ mr: 1, color: 'text.main' }} title="Voltar para administrador" size="large" onClick={() => goToAdm()}>
              <Icon icon="ci:house-remove" width={25} height={25} />
            </MIconButton>


            : ''}
          {localStorage.getItem('accessTokenLic') && localStorage.getItem('typeUser') ? (
            <MIconButton sx={{ mr: 1, color: 'text.main' }} title="Voltar para gerencial" size="large" onClick={() => goToManage()}>
              <Icon icon="fluent:people-swap-20-regular" width={25} height={25} />
            </MIconButton>
          ) : (
            ''
          )}

          {pendencies.length > 0 && (
            <Alert
              onClick={() => setPendencies([])}
              sx={{ position: 'absolute', top: '1em', right: '1em', zIndex: 1 }}
              severity="error"
            >
              <AlertTitle style={{ textAlign: 'left' }}>Você possui pendências</AlertTitle>
              {pendencies.map((p) => (
                <li>{p}</li>
              ))}
            </Alert>
          )}

          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );




}
